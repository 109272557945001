import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import { HelperService } from 'src/app/api/helper.service';
import { colorConfig } from 'src/environments/color-configs';
import { AuthService } from 'src/utils/auth.service';

import jwt_decode, { JwtPayload } from 'jwt-decode';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { PDFDocumentProxy, PDFProgressData } from 'ng2-pdf-viewer';

@Component({
  selector: 'app-jasper-report',
  templateUrl: './jasper-report.component.html',
  styleUrls: ['./jasper-report.component.scss']
})
export class JasperReportComponent implements OnInit {

  tabs = [{ title: 'Home', contentType: 'home', selectedAgain: false }];
  nextTabIndex = 1;
  formClass = 'leftView';
  selectedPortal: any;
  labelColor: string = 'black';
  allPortals = [];
  searchData: any = {}
  bgColor = 'rgb(253, 235, 224)';
  domain = 'snowpaths';
  buttonTextColor = 'black';
  urlOrigin = '';
  searchBg = 'rgb(221, 92, 15)';
  authResult: any;
  orgId: any = '';
  username: string = '';
  lastVisitedPortal: any = '';
  userData: any = {};
  OrgDetails: {} = {}
  routesArray=[];
  allTType = [{
    Id: 'all',
    Name: "all"
  }]
  allTruck = [{
    Id: 'all',
    Description: "all"
  }]
  loaded: boolean = false;
  trucksArray = [];
  selectedTruck
  disableSearchButton: boolean = true;
  StartTime = new Date();
  StopTime = new Date();
  calanderData: any = {};
  selectedTabIndex= new FormControl(0);
  dailyReports = [];
  selectedPdf: any = '';
  selectedReportType:string = '';
  viewBy: string = 'list';
  reportTypes = [
    {
      "tabLabel":"Daily Report",
      "type": "daily",
      "header": "Daily Report",
      "description": "Breakdown of a vehicle's daily movements"
    },
    {
      "tabLabel":"Disconnect Report",
      "type": "revealTruckStoppageReport",
      "header": "Reveal Disconnect Report",
      "description": "Breakdown of total reveal vehicle's disconnect report."
    },
    {
      "tabLabel":"Distance Report",
      "type": "distanceTravelledReport",
      "header":  "Distance Travelled Report",
      "description": "Breakdown of total vehicle's distance travelled report."
    },
    {
      "tabLabel":"Salt Report",
      "type": "SaltSpreadByDayReport",
      "header":  "Salt Spread Report",
      "description": "Breakdown of total vehicle's salt spread by day report."
    },
    {
      "tabLabel":"Cost Report",
      "type": "CostAnalysisReport",
      "header":  "Cost Analysis Report",
      "description": "Breakdown of total vehicle's cost analysis report."
    },
    {
      "tabLabel":"Street Sweeper Count Report",
      "type": "StreetSweeperReport",
      "header":  "Street Sweeper Count Report",
      "description": "Breakdown of total vehicle's street sweeper count report."
    }
  ]
  costPerMileData = [
    {
      label: 0,
      value: 0
    },
    {
      label: 0.25,
      value: 0.25
    },
    {
      label: 0.5,
      value: 0.5
    }
  ]
  idleCostPerMileData = [
    {
      label: 0,
      value: 0
    },
    {
      label: 0.25,
      value: 0.25
    },
    {
      label: 0.5,
      value: 0.5
    }
  ]
  sweeperReportTypeData = [
    {
      label: "Count",
      value: "Count"
    }
  ]
  // {
  //   label: "Street Sweeper",
  //   value: "StreetSweeper"
  // },
  costPerMile = this.costPerMileData[2]
  idleCostPerMile = this.idleCostPerMileData[1]
  sweeperReportType = this.sweeperReportTypeData[0]

  constructor(private helper: HelperService, private apiService: ApiService,
    private authService: AuthService, private router: Router, private datepipe: DatePipe) { }

  ngOnInit() {
    const urlOrigin = window.location.origin;
    this.urlOrigin = window.location.origin;
    let isAvailable = urlOrigin.includes("streetpaths");
    let isFleetpathAvailable = urlOrigin.includes("fleetpaths");
    if (isFleetpathAvailable) {
      this.domain = 'fleetpaths';
    }
    if (isAvailable) {
      this.domain = 'streetpaths';
    }
    this.authService.getAuthenticatedUser().getSession((err: any, session: any) => {
      if (session) {
        this.viewBy = window.innerWidth < 640 ? 'grid':'list';
        this.authResult = session;
        this.apiService.setAuthResult(session);
        let tokenValue = jwt_decode<JwtPayload>(session.getIdToken().getJwtToken());
        this.orgId = Number(tokenValue['custom:organizationid']);
        this.searchData['OrgId'] = Number(this.orgId);
        if (this.apiService.getLocalStorage('startTime')) {
          this.StartTime = new Date(JSON.parse(this.apiService.getLocalStorage('startTime')))
        }
        if (this.apiService.getLocalStorage('stopTime')) {
          this.StopTime = new Date(JSON.parse(this.apiService.getLocalStorage('startTime')))
        }
        this.getUserDetails();
      } else {
        this.router.navigate[('/')]
      }
    });
    if (!this.authResult) {
      localStorage.clear();
      this.router.navigate[('/')]
    }
    this.StopTime.setMinutes(59);
    this.StopTime.setSeconds(0);
    this.StopTime.setHours(23);
    this.StartTime.setMinutes(0);
    this.StartTime.setSeconds(0);
    this.StartTime.setDate(this.StopTime.getDate());
    this.StartTime.setHours(0)
    this.domain=this.apiService.getLocalStorage('domain');
    this.handlePortalStyles();
  }
  handlePortalStyles() {
    this.buttonTextColor = this.domain === 'snowpaths' ? colorConfig[this.domain]['buttonColorBlack'] :
      (this.domain === 'streetpaths' || this.domain === 'fleetpaths' || this.domain === 'mover') ? colorConfig[this.domain]['buttonColorBlack'] : 'black'
    this.bgColor = colorConfig[this.domain]['reports']['background'];
    this.searchBg = colorConfig[this.domain]['reports']['searchBg'];
    // this.labelColor = colorConfig[this.domain]['reports']['labelColor'];
    // if(this.domain === 'mower'){
    //   this.labelColor = 'black'
    // }
  }
  getLatestReport(event){
    this.searchData.PortalId = Number(event);
    this.dailyReports = [];
    this.getdailyReportsData();
  }
  
  addTab(tle, contentT, changeTab) {
    this.selectedReportType = contentT;
    var title = tle?tle:(contentT === 'daily'?'Daily report':contentT === 'revealTruckStoppageReport'?'Disconnect Report':
      contentT === 'distanceTravelledReport'?'Distance Report':contentT === 'SaltSpreadByDayReport'?'Salt Report':
      contentT === 'CostAnalysisReport'?'Cost Analysis Report':contentT === 'StreetSweeperReport'?'Street Sweeper Report':'Report')
    if((contentT === 'daily' || contentT === 'revealTruckStoppageReport' || contentT === 'distanceTravelledReport'
      || contentT === 'SaltSpreadByDayReport' || contentT === 'CostAnalysisReport' || contentT === 'StreetSweeperReport'
    ) && !changeTab){
      this.generateDailyReport();
    }
    
    var selectedTab = this.tabs.filter(tab => {
      return tab.contentType === contentT
    })
    if(selectedTab.length === 0){
      this.tabs.push({ title: title, contentType: contentT, selectedAgain: false });
      if(changeTab){
        this.selectedTabIndex.setValue(this.tabs.length - 1);
      }
    }else {
      var tabIndex = this.tabs.findIndex(tab => {return tab.contentType === contentT})
      this.tabs[tabIndex].selectedAgain = true;
        this.selectedTabIndex.setValue(tabIndex);
      setTimeout(() => {
        this.tabs[tabIndex].selectedAgain = false;
      }, 4000);
    }
  }

  closeTab(index: number) {
    this.tabs.splice(index, 1);
  }
  async getPortals() {
    var url = 'portal?orgId=' + this.orgId;
    var selectedPortalObj: any = {}
    this.apiService.getByUrl(url).subscribe(
      (res: any) => {
        console.log(res)
        this.allPortals = res['portals'];
        this.selectedPortal = this.lastVisitedPortal?Number(this.lastVisitedPortal):this.allPortals[0]['Id'];
        this.searchData['PortalId'] = this.lastVisitedPortal?Number(this.lastVisitedPortal):this.allPortals[0]['Id'];
        this.getRoutes();
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  async getRoutes() {

    var url = 'route?orgId=' + this.orgId + '&portalId=' + this.selectedPortal;
    this.apiService.getByUrl(url).subscribe((data: any) => {
      this.getTrucks();
      if (data['routes'].length > 0) {
        this.routesArray = this.allTType.concat(data['routes']);
      } else {
        this.routesArray = this.allTType;
      }
      if(this.routesArray.length > 0){
        this.routesArray = this.routesArray.filter(function( obj ) {
          return obj.Name !== 'Any';
        });
      }
      this.searchData['ZoneId']=this.routesArray[0]['Id']
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
    this.loaded=true;
  }
  async getTrucks() {
    var url = 'truck?orgId=' + this.orgId + '&portalId=' + this.selectedPortal+'&truckType=all'+'&includeInactiveTrucks=true';;
    this.apiService.getByUrl(url).subscribe((data: any) => {
      // this.getStatusKey();
      if (data.length > 0) {
        this.trucksArray = this.allTruck.concat(data);
      } else {
        this.trucksArray = this.allTruck;
      }
      this.searchData['TruckId']=this.trucksArray[0]['Id']
      this.selectedTruck=this.trucksArray[0];
      this.disableSearchButton=false;
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
    // this.getStopTableData();
  }
  getChange(key,event){
    this.selectedTruck = event.value;
    this.searchData[key]=event.value.Id;
  }
  async getUserDetails() {
    var username = this.apiService.getLocalStorage('username');
    this.username = username;
    var url = 'user?name=' + username.toLowerCase() + '&page=0&size=10&orgId=' + this.orgId;
    this.apiService.getByUrl(url).subscribe((data: any) => {
      console.log(data);
      if (data.users[0]) {
        this.lastVisitedPortal = Number(data.users[0].lastVisitedPortal);
        this.userData = data.users[0];
      }
      this.getOrgDetails();
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  async getOrgDetails() {
    var url = 'organization?orgId=' + this.orgId;
    this.apiService.getMapDetails(url).subscribe((data: any) => {
      console.log(data);
      this.OrgDetails = data;
      this.getPortals();

    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  dateChange(getType,getStartDate){
    console.log(getStartDate)
    if(getType === 'StartTime'){
      this.StopTime = new Date(this.StartTime)
      this.StopTime.setMinutes(0);
      this.StopTime.setSeconds(0);
      // this.StartTime.setMinutes(0);
      // this.StartTime.setSeconds(0);
      this.StopTime.setDate(this.StartTime.getDate() + 1);
      this.StopTime.setHours(this.StartTime.getHours());
    }
  }
  onMonthChange(event){
    this.calanderData= {}
    // Calculate the number of days in the month
    const numberOfDays = new Date(event.year, event.month + 1, 0).getDate();
    var startDate = event.year+'-'+event.month+'-'+'01'
    var endDate = event.year+'-'+event.month+'-'+numberOfDays
    this.getCalanderDates(startDate,endDate)
  }
  onOpenCalendar(event) {
    const currentDate = new Date(this.StartTime);
    // Get the month (0-indexed) and year
    const month = currentDate.getMonth()+1;
    const year = currentDate.getFullYear();

    // Calculate the number of days in the month
    const numberOfDays = new Date(year, month, 0).getDate();
    var startDate = year+'-'+month+'-'+'01'
    var endDate = year+'-'+month+'-'+numberOfDays
    this.getCalanderDates(startDate,endDate)
  }
  getCalanderDates(startDate,endDate){
    var url = 'spDataForDate';
    var payload = {
      "orgId":Number(this.orgId),
      "portalId":this.selectedPortal,
      "startDate":startDate,
      "endDate":endDate
    }
    this.apiService.postNewdata(url, payload).subscribe(
      (res: any) => {
        this.calanderData= res
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  async generateDailyReport(){
    var type = this.selectedReportType === 'daily'?'TruckDailyReport':this.selectedReportType === 'distanceTravelledReport'?'TruckDistanceTravelledReport':this.selectedReportType === 'SaltSpreadByDayReport'?'SaltSpreadByDayReport':
    this.selectedReportType === 'CostAnalysisReport'?'CostAnalysisReport':this.selectedReportType === 'StreetSweeperReport'?'StreetSweeperReport':this.selectedReportType ==='revealTruckStoppageReport'?'RevealTruckStoppageReport':'';
    var url = 'jasperReports?reportType='+type
    var payload = Object.assign({},this.searchData);
    payload['StartTime'] = this.datepipe.transform(this.StartTime, 'yyyy-M-dd HH:mm:00.000000')
    payload['StopTime'] = this.datepipe.transform(this.StopTime, 'yyyy-M-dd HH:mm:00.000000')
    if(this.selectedReportType === 'CostAnalysisReport'){
      payload['costPerMile']=this.costPerMile.value;
      payload['idleCostPerMile']=this.idleCostPerMile.value;
    }
    if(this.selectedReportType === 'StreetSweeperReport'){
      payload['sweeperReportType']=this.sweeperReportType.value;
    }
    if (payload['TruckId'] === 'all') { 
      payload['TruckId'] = null 
    }else {
      payload['TruckId']=Number(payload['TruckId'])
    }
    if (payload['ZoneId'] === 'all') { 
      payload['ZoneId'] = null
     }else {
      payload['ZoneId']=Number(payload['ZoneId'])
     }
     payload['PortalId']=Number(payload['PortalId'])
    await this.apiService.postNewdata(url, payload).subscribe(
      (res: any) => {
        this.helper.openQuickSnackBar("Report generation initialized","Ok")
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  onTabChanged(event: any){
    this.dailyReports = [];
    console.log(this.tabs)
    this.selectedTabIndex.setValue(event);
    this.selectedReportType = this.tabs[event]['contentType'];
    if(this.selectedReportType === 'daily' || this.selectedReportType === 'revealTruckStoppageReport'
      || this.selectedReportType === 'distanceTravelledReport' || this.selectedReportType === 'SaltSpreadByDayReport' || 
      this.selectedReportType === 'CostAnalysisReport' || this.selectedReportType === 'StreetSweeperReport'
    ){
      this.selectedPdf = '';
      this.getdailyReportsData();
    }
  }
  async getdailyReportsData(){
    var type = this.selectedReportType === 'daily'?'TruckDailyReport':this.selectedReportType === 'revealTruckStoppageReport'?'RevealTruckStoppageReport':
    this.selectedReportType === 'distanceTravelledReport'?'TruckDistanceTravelledReport':this.selectedReportType === 'SaltSpreadByDayReport'?'SaltSpreadByDayReport':
    this.selectedReportType === 'CostAnalysisReport'?'CostAnalysisReport':this.selectedReportType === 'StreetSweeperReport'?'StreetSweeperReport':''
    var url = 'jasperReports?orgId='+this.orgId+'&portalId='+this.searchData.PortalId+'&reportType='+type;
    await this.apiService.getByUrl(url).subscribe(
      (res: any) => {
        console.log(res)
        if(res &&res['length'] && res['length'] > 0){
          this.dailyReports = res
        }
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  getObjbyId(id){
    var name = 'Snow  '
    return name
  }
  showPdf(report){
    this.selectedPdf = report.ReportS3Url;
    this.apiService.showLoader();
  }
  onLoadPdfError(event){
    this.helper.openQuickSnackBar("Something problem on report PDF. Regenerate again","Ok")
  }
  onProgress(progressData: PDFProgressData) {
    this.apiService.hideLoader();
  }
  afterPDFLoadCompleted(pdf: PDFDocumentProxy){
    this.apiService.hideLoader();
  }
  downloadPdf(){
    this.apiService.downloadPdf(this.selectedPdf).subscribe(
      (data: Blob) => {
      this.helper.downloadPdfFromBlob(data,this.selectedPdf.split("/").pop())
      },
      error => {
        console.error('Error downloading PDF:', error);
      }
    );
  }
}
