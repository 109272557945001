import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatCheckboxChange, MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ColorPickerComponent } from 'smart-webcomponents-angular/colorpicker';
import { OverlayPanel } from 'primeng-lts/overlaypanel';
import { ApiService } from 'src/app/api/api.service';
import { HelperService } from 'src/app/api/helper.service';
import { ColorEvent } from 'ngx-color';
import * as mom from 'moment-timezone';
import { colorConfig } from 'src/environments/color-configs';
import { MatSort, Sort } from '@angular/material/sort';
import { passwordStrengthValidator } from '../../../api/password.validator';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { AuthService } from 'src/utils/auth.service';


@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  @ViewChild('colorpicker', { read: ColorPickerComponent, static: false }) colorpicker!: ColorPickerComponent;
  @ViewChild('empTbSort', { static: true }) empTbSort = new MatSort();
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    this.StoppageArray.sort = value;
  }
  title: string;
  thBg = '#e9ba9e';
  domain = 'snowpaths';
  welcomeMsg = ''
  publicData: any;
  welcomeLogo = '';
  message: string;
  emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$";
  showNewTType = false;
  showZoneHelp = false;
  zoneHelpS3Url = '';
  dataSource = new MatTableDataSource();
  showWelcome = false;
  salterDataColorRange:any;
  polygonCoordinates: google.maps.LatLngLiteral[] = [];
  portalId:number = 0;
  showSalterLegend = false;
  showSpalsh= false;
  showHelp = false;
  userForm:FormGroup;
  changePasswordForm:FormGroup;
  submitted = false;
  usernameFormat="<name>@<organization>.com"
  emailFormat="<name>@<domain>.com"
  helpUrl = '';
  showStoppage = false;
  showTable = false;
  noRecords = false;
  showUserDialog = false;
  showChangePassword = false;
  showNewGrid=false;
  passwordPattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@#$!%*?&])[A-Za-zd$@#$!%*?&].{7,15}";
  newPassword = '';
  confirmPassword = '';
  showPasswordError=false;
  apiType = '';
  StoppageArray = new MatTableDataSource();
  selectedStop = '';
  displayCols = ['truckDescription', 'startTime', 'endTime', 'totalTime']
  truckObj = {};
  gridObj={
    name:"",
    orgName: '',
    userEmail:'',
    opacity:0,
    gridSize:0,
    fillColor:"green",
    strokeColor:"red",
    orgId:0,
    portalId:0,
    polygonCoordinates:[]
  };
  userObj = {};
  sendEmail=false;
  orgId = '';
  selectedIcon = {};
  allPortals = [];
  selectedPortal: any = '';
  currentPortal: any
  selectedNonGisZone: any = [];
  showCopyZones:boolean = false;
  portalCheckBoxes = [];
  UpdateUser={};
  showPortalCheckBoxes = false;
  selectedColor = '#1e78fb';
  timeZone: any;
  urlOrigin='';
  sensors = [];
  opacityOptions: number[]=[];
  gridSizeList: number[] = [];
  roles = [
    { label: "Admin", value: "Admin" },
    { label: "User", value: "User" },
  ]
  // portals = [
  //   { label: "Snow", value: 1 },
  //   { label: "Refuse", value: 2 },
  //   { label: "Street Sweeper", value: 3 },
  //   { label: "NONE", value: 4 },
  // ];
  truckIcons = [];
  ApiStateMessage = '';
  completedPercentage: number = 0;
  allPortalNonGisZones: any = {};
  newAlerts: any = [];
  showNewSystemUpdate: boolean = false;
  username: any = '';
  dontShowCheckbox: boolean = false;
  userPoolData:any = {}
  mfaDropdownSettings: IDropdownSettings = {};
  mfaTypes: any = [];
  selectedMfaType: any = []

  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<EditComponent>, private authService: AuthService,
    private helper: HelperService, private apiService: ApiService, private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any, private changeDetectorRef: ChangeDetectorRef) {
    if (this.data.type && (this.data.type === 'post' || this.data.type === 'patch')) {
      this.title = this.data.title;
      this.truckObj = this.data.data.editData;
      this.orgId = this.data.orgId;
      this.apiType = this.data.type;
      if(this.truckObj && this.truckObj['TruckIconColor']){
        this.selectedColor = this.truckObj['TruckIconColor'];
        this.selectedIcon['Id'] = this.truckObj['TruckIconId'];
      }
    }
    if (this.data.type && (this.data.type === 'userPost' || this.data.type === 'userPatch')) {
      this.title = this.data.title;
      this.userPoolData = this.data.data.userPoolData;
      if(this.data.data.userPoolData.mfaTypes.length > 0){
        this.data.data.userPoolData.mfaTypes.forEach(element => {
          var mfaObj = {
            select: (this.data.data.editData && this.data.data.editData['mfaTypes'] && this.data.data.editData['mfaTypes'].includes(element))?true:false,
            label: element,
            value: element
          }
          this.mfaTypes.push(mfaObj)
        });
        console.log(this.mfaTypes)
      }
      if(this.data.data.editData['phoneNumber']){
        var re = /\+1/gi; 
    // Use of String replace() Method
      this.data.data.editData['phoneNumber'] = this.data.data.editData['phoneNumber'].replace(re, ""); 
    }
      this.userObj = this.data.data.editData;
      console.log(this.userObj)
      this.userObj['isMFAActive'] = this.userPoolData.mfa === 'OFF'?false:true;

    if(this.data.type === 'userPost'){
      this.sendEmail = this.data.data.sendEmail;
    }
      this.orgId = this.data.orgId;
      this.apiType = this.data.type;
      this.getPortals();
      this.showUserDialog = true;
    }
    if (this.data.type && this.data.type === 'welcome') {
      this.title = this.data.title;
      this.welcomeMsg = this.data.message;
      this.publicData = this.data.data.publicData;
      this.welcomeLogo = this.data.data.welcomeLogo;
      this.orgId = this.data.orgId;
      this.showWelcome = true;
    }
    if (this.data.type && this.data.type === 'viewSalterLegend') {
      this.title = this.data.title;
      this.salterDataColorRange = this.data.data.salterLegend;
      this.showSalterLegend = true;
    }
    if (this.data.type && this.data.type === 'newSystemUpdate') {
      this.title = this.data.title;
      this.newAlerts = this.data.data.alerts?this.data.data.alerts:{};
      this.username = this.data.data.username.split('@')[0];
      this.showNewSystemUpdate = true;
    }
    if (this.data.type && this.data.type === 'copyZones') {
      this.title = this.data.title;
      this.orgId = this.data.orgId;
      this.allPortals = this.data.data.allPortals;
      this.currentPortal = this.data.data.currentPortalId;
      this.selectedNonGisZone = this.data.data.currentZone;
      this.getZoneDetails();
      this.showCopyZones = true;
    }
    if (this.data.type && this.data.type === 'addGrid') {
      this.title = this.data.title;
      this.gridObj.polygonCoordinates = this.data.data.polygonCoordinates;
      this.gridObj.orgId= Number(this.data.orgId);
      this.gridObj.orgName= this.data.orgName;
      this.gridObj.userEmail= this.data.userEmail;
      this.gridObj.portalId = this.data.portalId;
      for(let i = 8; i <= 100; i++) {
        this.gridSizeList.push(i);
      }
      for(let i = 1; i <= 10; i++) {
        this.opacityOptions.push(i/10);
      }
      this.showNewGrid = true;
    }
    if (this.data.type && this.data.type === 'splash') {
      this.title = this.data.title;
      this.showSpalsh = true;
    }
    if (this.data.type && this.data.type === 'helpZoneCreate') {
      this.title = this.data.title;
      this.showZoneHelp = true;
      this.zoneHelpS3Url = data.data.helpPdfS3Url;
      // 'https://spmapskmz.s3.us-east-1.amazonaws.com/UserManual/Admin/Administrators - Instruction manual for Snowpaths & StreetPaths.pdf'
    }
    if (this.data.type && this.data.type === 'help') {
      this.title = this.data.title;
      this.welcomeMsg = this.data.message;
      this.publicData = this.data.data.publicData;
      this.welcomeLogo = this.data.data.welcomeLogo;
      this.orgId = this.data.orgId;
      this.helpUrl = this.data.helpUrl;
      this.showHelp = true;
    }
    if (this.data.type && this.data.type === 'viewStoppage') {
      this.title = this.data.title;
      this.selectedStop = this.data.data.selectedIconTitle;
      //   this.data.data.stoppageRecords.forEach(item =>{
      //     if(item.startTime){
      //         item.startTime = this.getDataChange(item.startTime)
      //     }
      //     if(item.endTime){
      //       item.endTime = this.getDataChange(item.startTime)
      //   }
      // });
      this.StoppageArray = new MatTableDataSource(this.data.data.stoppageRecords);
      this.timeZone = this.data.data.timeZone;
      if (!this.data.data.stoppageRecords) {
        this.noRecords = true
      }
      this.showTable = true;
      this.showStoppage = true;
    }
  }
  get fval() {
    return this.userForm.controls;
  }
  get pval() {
    return this.changePasswordForm.controls;
  }

  ngOnInit() {
    this.urlOrigin = window.location.origin;
    this.domain = this.apiService.getLocalStorage('domain');
    if(this.domain){
      this.thBg = colorConfig[this.domain]['thBackground'];
    }
    this.userForm = this.fb.group({
      email: ['',[
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
        username: ['',[
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      password: [null, [Validators.required,Validators.minLength(8),passwordStrengthValidator]],
      phoneNumber: [null, [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      role: ["",Validators.required],
    })
    this.changePasswordForm = this.fb.group({
      newPassword: [null, [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@#$!%*?&])[A-Za-zd$@#$!%*?&].{7,15}')]],
      confirmPassword: [null, [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@#$!%*?&])[A-Za-zd$@#$!%*?&].{7,15}')]],
    })
    
    if (this.data.type && (this.data.type === 'post' || this.data.type === 'patch')) {
      this.getPortals();
      // this.getSensorTypes();
      // this.getTruckIcons();
    }
    if (this.data.type && (this.data.type === 'changePassword')) {
      this.title = this.data.title;
      this.orgId = this.data.orgId;
      this.showChangePassword=true;
    }
    if(this.apiType === "userPatch"){
      this.userForm.controls['email'].clearValidators();
      this.userForm.controls['username'].disable();
      // this.userForm.controls['password'].clearValidators();
      this.userForm.controls['phoneNumber'].clearValidators();
      this.userForm.controls['role'].clearValidators();
    }
  }
  async getTruckIcons() {
    var url = 'truckIcon';
    await this.apiService.getByUrl(url).subscribe(
      (res: any) => {
        console.log(res)
        this.truckIcons = res;
        this.showNewTType = true;
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  async getSensorTypes() {
    var url = 'networkFleetSensor';
    await this.apiService.getByUrl(url).subscribe(
      (res: any) => {
        console.log(res)
        this.sensors = res;
        this.getTruckIcons()
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  async getPortals() {
    var url = 'portal?orgId=' + this.orgId;
    var checkedState = false;
    await this.apiService.getByUrl(url).subscribe(
      (res: any) => {
        console.log(res)
        this.allPortals = res['portals'];
        this.allPortals.map((portal) => {
          if(this.apiType === 'userPost'){
            checkedState=true;
          }
          if(this.apiType === 'userPatch'){
            checkedState = this.userObj['allowedPortals'].includes(portal.Id)?true:false;
          }
          var obj = {
            select: checkedState,
            Name: portal.Name,
            Id: portal.Id
          }
          this.portalCheckBoxes.push(obj);
        })
        this.showPortalCheckBoxes = true;
        if(this.data.type === 'post' || this.data.type === 'patch'){
          this.getSensorTypes();
        }
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  createTruckType(getObj) {
    var payload = {};
    var url = '';
    if (this.apiType === 'post') {
      payload = getObj;
      url = 'truckType'
    }
    if (this.apiType === 'patch') {
      payload = {
        "Id": getObj['Id'],
        "Name": getObj['Name'],
        "Sensor1Id": this.truckObj['Sensor1Id'],
        "Sensor2Id": this.truckObj['Sensor2Id'],
        "PortalId": this.truckObj['PortalId'],
      }
      url = 'truckType?truckTypeId='
    }
    payload['TruckIconId'] = this.selectedIcon['Id'];
    payload['TruckIconColor'] = this.selectedColor;
    payload['OrganizationId'] = Number(this.orgId);
    console.log(payload)
    this.updateTruckType(url, this.apiType, payload)
  }
  
  createUser(form: NgForm) {
    console.log(this.userObj)
    if(this.apiType === 'userPatch' && (this.userForm.controls['password'].value === ''
    || this.userForm.controls['password'].value === null)){
      this.userForm.controls['password'].clearValidators();
      this.userForm.controls['password'].updateValueAndValidity();
    }
    this.submitted = true;
    var mobileNo = this.userObj['phoneNumber'];
    if(form.valid){
    var payload = {};
    var api = '';
    var url = ''
    var checkPh = this.userObj['phoneNumber'].includes("+1")
    if(checkPh){
      this.userObj['phoneNumber'] = this.userObj['phoneNumber'].replace(/\+1/g, "")
    }
    if (this.apiType === 'userPost') {
      payload = Object.assign({},this.userObj);
      payload['phoneNumber']= "+1"+mobileNo;
      payload['organizationid'] = this.orgId;
      api = 'post';
      url = 'user?sendEmail='+this.sendEmail+'&portalUrl='+this.urlOrigin;
    }
    console.log(this.userObj)
    if (this.apiType === 'userPatch') {
      payload = this.UpdateUser;
      if(this.UpdateUser['phoneNumber']){
        payload['phoneNumber']= "+1"+this.UpdateUser['phoneNumber'];
      }
      // if(payload['totpDeviceRegistered']){
        payload['mfaTypes'] = {};
        payload['isMFAActive'] = this.userObj['isMFAActive'];
        this.mfaTypes.forEach(element => {
          if(payload['isMFAActive']){
            payload['mfaTypes'][element.label]=element.select;
          }
        });
        payload['mfaTypes'].preferredMfa= this.userObj['userPreferredMFA'];
      // }
      api = 'patch';
      url = 'user?username=';
    }
    // if(payload['allowedPortals'] && payload['allowedPortals'] === ''){
      payload['allowedPortals'] = '';
    this.portalCheckBoxes.map((portal) => {
      if (payload['role'] !== "Admin") {
        if (portal.select) {
          payload['allowedPortals'] += portal.Id + ',';
        }
      }else {
        payload['allowedPortals'] += portal.Id + ',';
      }
    })
    // if (payload['allowedPortals']) { 
    // }
    payload['allowedPortals']=payload['allowedPortals'].slice(0, -1) 
  // }
    
  console.log(this.userObj)
  // var checkPh = this.userObj['phoneNumber'].includes("+1")
  // if(checkPh){
  //   this.userObj['phoneNumber'] = this.userObj['phoneNumber'].replace(/\+1/g, "")
  // }
    console.log(payload)
    this.update(url, api, payload)
  } else{
    console.log("invalid fields")
  }
  }
  updateTruckType(Url, type, payload) {
    var patchIbjId = this.apiType === "patch" ? this.truckObj['Id'] : this.userObj['username']
    var url = '';
    if (type === 'post') {
      if(payload.Name && payload.Name !== '' && payload.PortalId && payload.TruckIconId){
      url = Url;
      this.apiService.postNewdata(url, payload).subscribe(
        (res: any) => {
          console.log(res)
          res['type']="created";
          // this.allPortals = res['portals'];
          this.dialogRef.close(res);
          this.helper.openSnackBar("Successfully created/updated", "OK");

        }, (error) => {
          console.log(error);
          this.helper.openSnackBar(error, "OK")
        }
      )} else{
        this.helper.openSnackBar("Warning! Some mandatory fields are empty.", "OK")
      }
    }
    if (type === 'patch') {
      url = Url + patchIbjId;
      this.apiService.updatedata(url, payload).subscribe(
        (res: any) => {
          console.log(res)
          res['type']="updated";
          this.dialogRef.close(res);
          this.helper.openSnackBar("Successfully created/updated", "OK");
          // this.allPortals = res['portals'];
        }, (error) => {
          console.log(error);
          this.helper.openSnackBar(error, "OK")
        }
      )
    }
  }
  update(Url, type, payload) {
    var patchIbjId = this.apiType === "patch" ? this.truckObj['Id'] : this.userObj['username']
    var url = '';
    if (type === 'post') {
      // if(payload.Name && payload.Name !== '' && payload.PortalId && payload.TruckIconId){
      url = Url;
      this.apiService.postNewdata(url, payload).subscribe(
        (res: any) => {
          console.log(res)
          res['type']="created";
          // this.allPortals = res['portals'];
          this.dialogRef.close(res);
          this.helper.openSnackBar("Successfully created/updated", "OK");

        }, (error) => {
          console.log(error);
          this.helper.openSnackBar(error, "OK")
        }
      )
    // } else{
        // this.helper.openSnackBar("Warning! Some mandatory fields are empty.", "OK")
      // }
    }
    if (type === 'patch') {
      url = Url + patchIbjId;
      this.apiService.updatedata(url, payload).subscribe(
        (res: any) => {
          console.log(res)
          res['type']="updated";
          this.dialogRef.close(res);
          this.helper.openSnackBar("Successfully created/updated", "OK");
          // this.allPortals = res['portals'];
        }, (error) => {
          console.log(error);
          this.helper.openSnackBar(error, "OK")
        }
      )
    }
  }
  postNewData(payload) {
    var url = 'smartPathPolygonGrid';
      this.apiService.postNewdata(url, payload).subscribe(
        (res: any) => {
          console.log(res)
          this.dialogRef.close(res);
          this.helper.openSnackBar("Successfully created zone "+res.name+".", "OK");

        }, (error) => {
          console.log(error);
          this.helper.openSnackBar(error, "OK")
        }
      )
  }
  addSelected(key: any, value: any) {
    if (key === 'Sensor1Id' || key === 'Sensor2Id' || key === 'PortalId') {
      value = Number(value);
    }
    this.truckObj[key] = value;
  }
  addSelected1(key: any, value: any) {
    this.userObj[key] = value;
    if(this.apiType === 'userPatch'){
      this.UpdateUser[key] = value;
    }
  }
  
  selectIcon(getIconData) {
    console.log(getIconData)
    this.selectedIcon = getIconData;
  }
  public highlight(event) {
    var target = event.target || event.srcElement || event.currentTarget;

    // target.classList.remove("bg-blue");
    // target.classList.add("bg-blue");

  }
  onChangeColor(event: ColorEvent) {
    this.selectedColor = event.color.hex;
  }
  onChangeGridColor(key: string,event: ColorEvent) {
    this.gridObj[key] = event.color.hex;
  }
  // getDataChange(getText){
  //   // if(col === 'startTime' || col === 'endTime'){
  //     const uTCDatetime = mom.tz(getText, "UTC").format();
  //   const to = mom.tz(uTCDatetime, this.timeZone).format("DD/MM/yyyy hh:mm A");
  //   var regExp = new RegExp(getText, 'gi');
  //   var newstr = getText.replace(regExp, to);
  //     return newstr
  //   // }else {
  //     // return getText
  //   // }
  // }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.StoppageArray.filter = filterValue;
    if (this.StoppageArray['filteredData'].length === 0) {
      this.noRecords = true;
    }
  }
  printStoppage() {
    var body = document.getElementById('body').innerHTML;
    var originalContents = document.body.innerHTML;
    var printStoppagesAdd = document.getElementById("printStoppage").innerHTML;

    //  document.body.innerHTML = printStoppagesAdd;
    //  document.body.innerHTML += printContents;
    document.getElementById('body').innerHTML = printStoppagesAdd;
    document.body['style'].height = '95%';
    window.print();

    document.getElementById('body').innerHTML = body;
    //  document.body.innerHTML = originalContents;
    window.close()
    window.location.reload()
    //  window.location.href=this.urlOrigin+'/Admin/Report';
  }
  activeChange(truckObj, ob: MatCheckboxChange) {
    console.log(ob.checked);
    console.log(this.portalCheckBoxes)
    if(this.apiType === 'userPatch'){
      this.UpdateUser['allowedPortals']='';
    }
  }
  onChangeUser(key,event){
    this.UpdateUser[key]=event.target.value;
    if(key === 'password' && event.target.value !== '' && this.apiType === 'userPatch'){
      this.userForm.controls['password'].setValidators([Validators.required,Validators.minLength(8),passwordStrengthValidator]);
      this.userForm.controls['password'].updateValueAndValidity();
    }
    if(key === 'password' && event.target.value === '' && this.apiType === 'userPatch'){
      this.userForm.controls['password'].clearValidators();
      this.userForm.controls['password'].updateValueAndValidity();
    }
    if(this.apiType === "userPatch" && event.target.value === ""){
      delete this.UpdateUser[key];
    }
  }
  omit_special_char(event)
{   
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
}
getHeader(col){
  var headerName = col === "truckDescription"?"Vehicle Description":col === "startTime"?"Start Time":
  col === "endTime"?"End Time":col === "totalTime"?"Total Time":col;
  return headerName;
}
changePassword(form:NgForm){
  this.submitted = true;
  var payload = {};
  var url = '';
    var mobileNo = this.userObj['phoneNumber'];
    if(form.valid){
      payload['password'] = this.newPassword;
      url = 'user?username='+localStorage.getItem('username');
      this.apiService.updatedata(url, payload).subscribe(
        (res: any) => {
          console.log(res)
          this.dialogRef.close(true);
          this.helper.openSnackBar("Successfully created/updated", "OK");
          // this.allPortals = res['portals'];
        }, (error) => {
          console.log(error);
          this.helper.openSnackBar(error, "OK")
        }
      )
    }
}
async getPortalId(portalId) {
  // this.showPage = false;
  this.apiService.showLoader();
  console.log(portalId);
  this.selectedPortal = Number(portalId);
  var selectedPortalObj = this.allPortals.find(obj => {
    return obj.Id === this.selectedPortal;
  })
  var isZoneExist = this.allPortalNonGisZones[portalId].filter((zone: any) => {
    return (zone.Name === this.selectedNonGisZone.Name && zone.IsActive)
  })
  this.apiService.hideLoader();
  if(isZoneExist.length >0){
    this.confirmCopyZone(selectedPortalObj, isZoneExist[0]);
  }
  // this.selectedNonGisZone


}
confirmCopyZone(portalObj: any, existZoneDetails: any){
  const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
    data: {
      title: 'Confirm to copy',
      message: 'This zone already exist on this portal '+portalObj.Name +'.  \n\n Please confirm that you\'d like to copy this zone:?: \n\n ' + this.selectedNonGisZone['Name']
    }
  });
  confirmDialog.afterClosed().subscribe(async result => {
    if (result === true) {
      var url = 'RouteNonGis?routeId=' + existZoneDetails['Id']+'&orgId='+this.orgId+'&portalId='+portalObj.Id
      this.delObject(url)
    }
  });
}
confirmNewAlert(){
  if (this.dontShowCheckbox === true) {
    var url = 'alerts';
    var activeAlertIds = this.apiService.getLocalStorage('activeAlertsId').split(',');
    var payload = {
      "alertIds":activeAlertIds.map((id) => {
        return Number(id)
      })
  }
  console.log(payload)
    this.apiService.updatedata(url, payload).subscribe(
      (res: any) => {
        console.log(res)
        this.helper.openSnackBar("New alerts verified.", "OK");
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
}
async delObject(url) {
  await this.apiService.deleteData(url).subscribe(
    (res: any) => {
      console.log(res);
      this.copyZones();
    }, (error) => {
      console.log(error);
    }
  )
} 
//copy individual zone to new portal
async copyZones(){
  var url = "RouteNonGis";
  this.ApiStateMessage = "Copying zones...";
  var index = 0;
    var payload = {
      "name": this.selectedNonGisZone['Name'],
      "orgId": Number(this.orgId),
      "portalId": this.selectedPortal,
      "truckTypeId": this.selectedNonGisZone['TruckTypeId'],
      "FillColor": this.selectedNonGisZone['FillColor'],
      "coordinates": this.selectedNonGisZone['Coordinates'],
      "orgName": "",
      "userEmail": ""
    }
  await this.apiService.postNewdata(url, payload).subscribe(
    (res: any) => {
      console.log(res)
        this.ApiStateMessage = "Zone copied successfully...";
        this.selectedPortal = '';
        this.getZoneDetails();
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    }
  )
}
// get all non gis zone with passing all portal ids
async getZoneDetails() {
  this.allPortals.map(async portal => {
    var url = 'RouteNonGis?orgId='+this.orgId+'&portalId='+ portal.Id;
      await this.apiService.getByUrl(url).subscribe((data: any) => { 
        this.allPortalNonGisZones[portal.Id] = data['routes'];
      }, (error) => {
        console.log(error);
      })
  })
}

mfaTypeChange(mfa, allMfas, ind, ob: MatCheckboxChange){
  var isAllDisabled = allMfas.filter((mfa: any) => {
    return mfa.select})
  if(this.mfaTypes.length === 1 || isAllDisabled.length === 1){
    ob.checked = true;
    if(this.mfaTypes.length === 1){
      // this.mfaTypes[0].select = true;
      this.mfaTypes[ind].select = true;
    }else {
    }
    this.userObj['userPreferredMFA'] = isAllDisabled[0].label;
    this.isAllMFADisabled();
    this.changeDetectorRef.detectChanges();
    this.helper.openQuickSnackBar("Any one MFA type required.","Ok")
    return ob.checked
  }
  console.log(this.mfaTypes);
}
mfaPreferedTypeChange(event){
  console.log(event)
}
isAllMFADisabled(){
  var list = this.mfaTypes.filter((mfa: any) => {
    return mfa.select})
    var res = (list && list.length === this.mfaTypes.length)? true:false;
  return res;
}

checkState(){
  var state = false;
  var isOneMfa = this.mfaTypes.filter(mfa => {
    return mfa.select === false
  })
  if((this.mfaTypes.length === 1 && this.mfaTypes[0].label !== 'SOFTWARE_TOKEN_MFA') || (this.mfaTypes.length === 2 && isOneMfa.length === 1)){
    state = true
  }else {
    state = false
  }
  return state
}
getPath(url){
  window.open(url, '_blank');
  // this.router.navigate([path]);
}
}
